import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './authentication/sign-in/sign-in.component';
import { AuthGuardServiceService } from './authentication/auth-guard-service.service';
import { NotfoundComponent } from './component/notfound/notfound.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { AddClientComponent } from './dashboard/add-client/add-client.component';
import { AddCategoryPriceComponent } from './dashboard/add-category-price/add-category-price.component';
import { SaleReportComponent } from './component/client/sale-report/sale-report.component';


const routes: Routes = [
  { path: '', component: SignInComponent, pathMatch: 'full' },
  
  { path: 'muhammadSaad', component: SignInComponent },

  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardServiceService] },
  { path: 'addClient', component: AddClientComponent, canActivate: [AuthGuardServiceService] },
  { path: 'addCategoryPrice', component: AddCategoryPriceComponent, canActivate: [AuthGuardServiceService] },

  
  { path: 'saleReport', component: SaleReportComponent, canActivate: [AuthGuardServiceService] },
  { path: '404', component: NotfoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
