import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { AuthServiceService } from '../authentication/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardServiceService {

  constructor(private authservice: AuthServiceService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    // return this.authservice.isAuthenticated(state.url);
    return this.authservice.isAuthenticated(); 
  }

}
