import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/authentication/auth-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  constructor(private router: Router, public auth: AuthServiceService) {
    
  }

  public checkSuperAdmin:boolean = false;

  ngOnInit(): void {
    
  }

  // ngAfterViewInit(){
  //   let tokenData = this.auth.getToken();
  //   if( tokenData != null && tokenData.role_name === "SuperAdmin"){
  //     this.checkSuperAdmin = true;
  //   }else{
  //     this.checkSuperAdmin = false;
  //   }
  // }

  public routeLinkActive;
  navigateToMenu(menuDTO, Params) {
    this.routeLinkActive = menuDTO;
    this.router.navigate(['/' + menuDTO], { queryParams: Params, skipLocationChange: true });
  }

  navigateNoParams(menuDTO) {
    this.routeLinkActive = menuDTO;
    this.router.navigate(['/' + menuDTO], { skipLocationChange: true });
  }

  logOut() {
    this.auth.logout();
    this.router.navigate(['/muhammadSaad'], { skipLocationChange: true });
  }


}
