<div class="container">
    <div class="row" *ngIf="success">
        <div class="col-12">
            <div class="p-3 mb-4 bg-success text-white"> {{success}}</div>
           
        </div>
    </div>
    <form #Client="ngForm" (ngSubmit)="addClient(Client)">
    <div class="row">
        <div class="col-lg-12">

                <h2>Add Client</h2>
                <div class="form-row">
                    <div class="col-md-3 mb-3">
                        <label for="validationTooltip01">Shop Name</label> 
                        <input type="text" class="form-control" [(ngModel)]="getFormData.company_name" name="company_name"   #company_name="ngModel" required>
                        <div [hidden]="company_name.valid || company_name.pristine" class="alert alert-danger">
                            Company Name Type is required
                        </div>
                    </div>
                   
                </div>
                <div class="form-row"> 
                    <div class="col-md-3 mb-3">
                        <label for="validationTooltip02">Cell</label>
                        <input type="number" class="form-control" [(ngModel)]="getFormData.cell" name="cell"  #cell="ngModel" required>
                        <div [hidden]="cell.valid || cell.pristine" class="alert alert-danger">
                            Cell is required
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label for="validationTooltip03">Name</label>
                        <input type="text" class="form-control" [(ngModel)]="getFormData.name" name="name"  #name="ngModel" >
                        
                    </div>
                    <div class="col-md-3 mb-3">
                        <label for="validationTooltip03">Date of Birth</label>
                        <input type="date" class="form-control" [(ngModel)]="getFormData.dob" name="dob"  #dob="ngModel" >
                        
                    </div>
                    <div class="col-md-3 mb-3">
                        <label for="validationTooltip04">Email</label>
                        <input type="email" class="form-control" [(ngModel)]="getFormData.email" name="email"  #email="ngModel" required>
                        <div [hidden]="email.valid || email.pristine" class="alert alert-danger">
                            email is required
                        </div>
                      
                    </div>
                    <div class="col-md-3 mb-3">
                        <label for="validationTooltip05">Password</label>
                        <input type="password" class="form-control" [(ngModel)]="getFormData.password" name="password"  #password="ngModel" required>
                        <div [hidden]="password.valid || password.pristine" class="alert alert-danger">
                            password is required
                        </div>
                      
                    </div>
                    <div class="col-md-3 mb-3">
                        <label for="validationTooltip01">API Check</label> 
                        <input type="password" class="form-control" [(ngModel)]="getFormData.check" name="check"   #check="ngModel">
                       
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="validationTooltip03">Address</label>
                        <input type="text" class="form-control" [(ngModel)]="getFormData.Address" name="Address"  #Address="ngModel" >
                    </div>
                </div>

            

               
                <button class="btn btn-primary" type="submit" [disabled]="!Client.form.valid" *ngIf="!loadingButton">Submit form</button>
                <button class="btn btn-primary" *ngIf="loadingButton">
                    <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </button>
            

        </div>
    
    </div>
</form>
</div>