<div class="container-fluid d-print-none">
    <div class="row">

        <div class="col-12">
            <h2> Sale Report User </h2>

            <div class="text-center" *ngIf="loadingPage">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <div class="row" *ngIf="!loadingPage">
                <div class="col-12">
                    <div class="form-row">
                        <div class="form-group row col-md-3">
                            <label class="col-sm-4 col-form-label">Cell</label>
                            <div class="col-sm-8">
                                <input type="text" [(ngModel)]="formDataOne.cell" name="cell" #cell="ngModel"
                                    appPhoneMask class="form-control">
                            </div>
                        </div>
                        <div class="form-group row col-md-4">
                            <label class="col-sm-4 col-form-label">Address</label>
                            <div class="col-sm-8">
                                <input type="text" [(ngModel)]="formDataOne.address" name="address" #address="ngModel"
                                    class="form-control">
                            </div>
                        </div>
                        <div class="form-group row col-md-3">
                            <label class="col-sm-4 col-form-label">Range Date</label>
                            <div class="col-sm-8">
                                <date-range-picker [(dateRange)]="dateRange"></date-range-picker>
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <button type="submit" class="btn btn-primary" (click)="saleReport()">Search</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="!loadingPage">
                <div class="col-12">
                    <table class="table table-striped" *ngIf="cehckValue">
                        <thead>
                            <tr>
                                <th>User Id</th>
                                <th>Name</th>
                                <th>Cell</th>
                                <th>Address</th>
                                <th>Date</th>
                                <th width="30%">Description</th>
                                <th>Delivery Type</th>
                                <th>Discount</th>
                                <th>Total</th>
                                <th>Received</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of report let i = index;">
                            <tr>
                                <td>{{item.user_id}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.cell}}</td>
                                <td>{{item.address}}</td>
                                <td>{{item.date | date : "yyyy-MM-dd"}}</td>
                                <td>
                                    <ul class="list-unstyled">
                                        <li class="media" *ngIf="item.filler">
                                            <div class="media-body">
                                                <span class="mt-0 mb-1">Filler: </span>
                                                <b>{{item.filler}}</b>
                                            </div>
                                        </li>
                                        <li class="media" *ngIf="item.ltr1_5">
                                            <div class="media-body">
                                                <span class="mt-0 mb-1">Liter1.5: </span>
                                                <b>{{item.ltr1_5}}</b>
                                            </div>
                                        </li>
                                        <li class="media" *ngIf="item.ltr6">
                                            <div class="media-body">
                                                <span class="mt-0 mb-1">Liter6: </span>
                                                <b>{{item.ltr6}}</b>
                                            </div>
                                        </li>
                                        <li class="media" *ngIf="item.ltr12">
                                            <div class="media-body">
                                                <span class="mt-0 mb-1">Liter12: </span>
                                                <b>{{item.ltr12}}</b>
                                            </div>
                                        </li>
                                        <li class="media" *ngIf="item.ltr19">
                                            <div class="media-body">
                                                <span class="mt-0 mb-1">Liter19: </span>
                                                <b>{{item.ltr19}}</b>
                                            </div>
                                        </li>
                                        <li class="media" *ngIf="item.ltr1000">
                                            <div class="media-body">
                                                <span class="mt-0 mb-1">Liter1000: </span>
                                                <b>{{item.ltr1000}}</b>
                                            </div>
                                        </li>
                                        <li class="media" *ngIf="item.ml330">
                                            <div class="media-body">
                                                <span class="mt-0 mb-1">Ml330: </span>
                                                <b>{{item.ml330}}</b>
                                            </div>
                                        </li>
                                        <li class="media" *ngIf="item.ml600">
                                            <div class="media-body">
                                                <span class="mt-0 mb-1">Ml600: </span>
                                                <b>{{item.ml600}}</b>
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    {{item.delivery_type}}
                                </td>
                                <td><b>{{item.discount | currency:'Rs.':'symbol':'3.0'}}</b></td>
                                <td><b>{{item.payment | currency:'Rs.':'symbol':'3.0'}}</b></td>
                                <td><b>{{item.pay_received | currency:'Rs.':'symbol':'3.0'}}</b></td>
                                <td class="text-danger"><b>{{item.balance  | currency:'Rs.':'symbol':'3.0'}}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12">
                    <div class="row justify-content-end" *ngIf="!cehckValue">
                        <div class="col-md-10 mt-2 mb-2">
                            <ul class="list-unstyled">
                                <li class="media">
                                    <div class="media-body">
                                        <span class="mt-0 mb-1">Name: </span>
                                        <b>{{report[0].name}}</b>
                                    </div>
                                </li>
                                <li class="media">
                                    <div class="media-body">
                                        <span class="mt-0 mb-1">Cell: </span>
                                        <b>{{report[0].cell}}</b>
                                    </div>
                                </li>
                                <li class="media">
                                    <div class="media-body">
                                        <span class="mt-0 mb-1">Address: </span>
                                        <b>{{report[0].address}}</b>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-2 mt-2 mb-2">
                            <button class="btn btn-sm btn-danger btn-block" (click)="downloadPDF()">Download PDF</button>
                        </div>
                    </div>

                    <div class="row" id="htmlData" #htmlData>
                      
                        <table class="table table-striped" id="my-table" width="100%" *ngIf="!cehckValue">
                            <thead>
                                <tr>
                                    <th>User Id</th>
                                    <th>Date</th>
                                    <th width="30%">Description</th>
                                    <th>Delivery Type</th>
                                    <th>Discount</th>
                                    <th>Total</th>
                                    <th>Received</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of report let i = index;">
                                <tr>
                                    <td>{{item.user_id}}</td>
                                    <td>{{item.date | date : "yyyy-MM-dd"}}</td>
                                    <td>
                                        <ul class="list-unstyled">
                                            <li class="media" *ngIf="item.filler">
                                                <div class="media-body">
                                                    <span class="mt-0 mb-1">Filler: </span>
                                                    <b>{{item.filler}}</b>
                                                </div>
                                            </li>
                                            <li class="media" *ngIf="item.ltr1_5">
                                                <div class="media-body">
                                                    <span class="mt-0 mb-1">Liter1.5: </span>
                                                    <b>{{item.ltr1_5}}</b>
                                                </div>
                                            </li>
                                            <li class="media" *ngIf="item.ltr6">
                                                <div class="media-body">
                                                    <span class="mt-0 mb-1">Liter6: </span>
                                                    <b>{{item.ltr6}}</b>
                                                </div>
                                            </li>
                                            <li class="media" *ngIf="item.ltr12">
                                                <div class="media-body">
                                                    <span class="mt-0 mb-1">Liter12: </span>
                                                    <b>{{item.ltr12}}</b>
                                                </div>
                                            </li>
                                            <li class="media" *ngIf="item.ltr19">
                                                <div class="media-body">
                                                    <span class="mt-0 mb-1">Liter19: </span>
                                                    <b>{{item.ltr19}}</b>
                                                </div>
                                            </li>
                                            <li class="media" *ngIf="item.ltr1000">
                                                <div class="media-body">
                                                    <span class="mt-0 mb-1">Liter1000: </span>
                                                    <b>{{item.ltr1000}}</b>
                                                </div>
                                            </li>
                                            <li class="media" *ngIf="item.ml330">
                                                <div class="media-body">
                                                    <span class="mt-0 mb-1">Ml330: </span>
                                                    <b>{{item.ml330}}</b>
                                                </div>
                                            </li>
                                            <li class="media" *ngIf="item.ml600">
                                                <div class="media-body">
                                                    <span class="mt-0 mb-1">Ml600: </span>
                                                    <b>{{item.ml600}}</b>
                                                </div>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        {{item.delivery_type}}
                                    </td>
                                    <td><b>{{item.discount | currency:'Rs. ':'symbol':'3.0'}}</b></td>
                                    <td><b>{{item.payment | currency:'Rs. ':'symbol':'3.0'}}</b></td>
                                    <td><b>{{item.pay_received | currency:'Rs. ':'symbol':'3.0'}}</b></td>
                                    <td class="text-danger"><b>{{item.balance  | currency:'Rs. ':'symbol':'3.0'}}</b></td>
                                </tr>
                            </tbody>
                            <tfoot style="background-color: #d3d3d3;">
                                <tr>
                                  <td colspan="4"> Payment Total - Received Total - Balance Total </td> 
                                  <td><b>{{Discount | currency:'Rs. ':'symbol':'3.0'}}</b></td> 
                                  <td><b>{{Total | currency:'Rs. ':'symbol':'3.0'}}</b></td>
                                  <td><b>{{Received | currency:'Rs. ':'symbol':'3.0'}}</b></td>
                                  <td class="text-danger"><b>{{Balance | currency:'Rs. ':'symbol':'3.0'}}</b></td>
                                </tr>
                              </tfoot>
                        </table>
                    </div>
                  
                </div>


            </div>
        </div>
    </div>
</div>