<!-- <ng-template [ngIf]="!auth.isAuthenticated()">
  <header>
    <div class="header-top-banner d-print-none"> <img src="assets/images/banner-top.jpg" alt="" class="img-fluid"> </div>
    <div class="container">
      <div class="row">

        <div class="col-sm-6">
          <nav class="navbar navbar-expand-lg">
            <a (click)="navigateNoParams('home')" class="navbar-brand d-flex align-items-center hidden-sm-up"><img
                src="assets/images/giftokarachi.png" alt="giftokarachi.com" class="img-reponsive"></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="fas fa-bars"></span>
            </button>
            <div class="collapse navbar-collapse mt-2" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                  <a class="nav-link" (click)="navigateNoParams('home')" [ngClass]="{'active': routeLinkActive === 'home' }"><i class="fab fa-houzz"></i> HOME </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="navigateToMenu('ledTv', { Category_id: 1 })" [ngClass]="{'active': routeLinkActive === 'ledTv' }"> <i class="fas fa-tv"></i>
                    LED TV</a>
                </li>
               

                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="{'active': routeLinkActive === 'mobile' }">
                    <i class="fas fa-mobile-alt"></i> MOBILE
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" (click)="navigateToMenu('mobile', { Category_id: 4, Product_Company_Name: 'SAMSUNG' })">Samsung</a>
                    <a class="dropdown-item" (click)="navigateToMenu('mobile', { Category_id: 4, Product_Company_Name: 'HUAWEI' })">Huawei</a>
                    <a class="dropdown-item" (click)="navigateToMenu('mobile', { Category_id: 4, Product_Company_Name: 'VIVO' })">Vivo</a>
                    <a class="dropdown-item" (click)="navigateToMenu('mobile', { Category_id: 4, Product_Company_Name: 'XIAOMI' })">Xiaomi</a>
                    <a class="dropdown-item" (click)="navigateToMenu('mobile', { Category_id: 4, Product_Company_Name: 'OPPO' })">Oppo</a>
                    <a class="dropdown-item" (click)="navigateToMenu('mobile', { Category_id: 4, Product_Company_Name: 'REALME' })">Real me</a> 
                    <a class="dropdown-item" (click)="navigateToMenu('mobile', { Category_id: 4, Product_Company_Name: 'INFINIX' })">Infinix</a>
                    <a class="dropdown-item" (click)="navigateToMenu('mobile', { Category_id: 4, Product_Company_Name: 'TECHNO' })">Techno</a>
                    <a class="dropdown-item" (click)="navigateToMenu('mobile', { Category_id: 4, Product_Company_Name: 'MOTOROLA' })">Motorola</a>
                    <a class="dropdown-item" (click)="navigateToMenu('mobile', { Category_id: 4, Product_Company_Name: 'NOKIA' })">Nokia</a> 
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div class="col-sm-3 col-8">
          <span class="cell">Call Us: <b> (+92) 311 002 2934 </b></span>
        </div>
        <div class="col-sm-3 col-4">
          <div class="cart" (click)="navigateNoParams('order')"><i class="fas fa-shopping-cart"></i> <span
              class="item-count">{{showNumber}}</span></div>
        </div>
      </div>
    </div>
    <a class="Page" (click)="navigateNoParams('muhammadSaad')">
      open
    </a>
  </header>
</ng-template> -->


<ng-template [ngIf]="auth.isAuthenticated()">
  <header>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark d-print-none">
      
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse" *ngIf="auth.roleSend()">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item ">
            <a class="nav-link" (click)="navigateNoParams('dashboard')" [ngClass]="{'active': routeLinkActive === 'dashboard' }">Dashboard</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="navigateNoParams('addClient')" [ngClass]="{'active': routeLinkActive === 'addClient' }">Add Client</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="navigateNoParams('addCategoryPrice')" [ngClass]="{'active': routeLinkActive === 'addCategoryPrice' }">Category Price</a>
          </li>
          
        </ul>
        <div class="form-inline mt-2 mt-md-0">
          <button class="btn btn-outline-success my-2 my-sm-0" type="button" (click)="logOut()">Log Out</button>
        </div>
      </div>

      <div class="collapse navbar-collapse" id="navbarCollapse" *ngIf="!auth.roleSend()">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item ">
            <a class="nav-link" (click)="navigateNoParams('saleReport')" [ngClass]="{'active': routeLinkActive === 'saleReport' }">Sale Report</a>
          </li>
        </ul>
        <div class="form-inline mt-2 mt-md-0">
          <button class="btn btn-outline-success my-2 my-sm-0" type="button" (click)="logOut()">Log Out</button>
        </div>
      </div>
    </nav>
  </header>
</ng-template>