<form (ngSubmit)="signIn(signin)" #signin="ngForm" class="form-signin">
    <div class="form-group">
      <label for="User Name">User Name</label>
      <input type="email" id="email" name="email" ngModel class="form-control" required>
    </div>
    <div class="form-group">
      <label for="Password">Password</label>
      <input type="password" id="password" name="password" ngModel class="form-control" required>
    </div>
  
    <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!signin.valid">Sign In</button>
  </form>
