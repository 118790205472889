import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../environments/environment";
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor(private router: Router,  private http: HttpClient) { }

  private token:any = null;
  private servicesUrl:string = 'http://www.giftokarachi.com/';

  //user signin
  signinUser(url, data){
    let serviceUrl = this.servicesUrl + url;
    
    this.http.post(serviceUrl, data).pipe(first()).subscribe(
      (data: any) => {
        if(data.Error){
          alert(data.Message)
        }else{
          this.storeToken(data);
          this.router.navigate(['/dashboard'], { skipLocationChange : true}); 
          console.log(data);
        }
        //return "Thank You for login";
      },
      (error) => {
        console.log(error);
      }
    );  
    
  }

  //get token
  getToken() {
    return this.token;
  }

  //check token not null
  // isAuthenticated(routerLink:string) {
  isAuthenticated() {
    if (this.token === null)
    {
      let userData = localStorage.getItem('userData');
      this.token = JSON.parse(userData);
    }
    


    return this.token != null;
  }

  roleSend(){
    if(this.token.role_name === "SuperAdmin"){
      return true;
    }else{
      return false;
    }
    
  }

  //logout
  logout(){
    this.token = null;
    localStorage.removeItem("userData");
  }

  storeToken(tokenData){
    localStorage.setItem("userData", JSON.stringify(tokenData));
    this.token = tokenData;
  }

  


}
