import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';
import { BaseResponseModule } from '../model/base-response.module';
import {environment} from "../../environments/environment";
import { AuthServiceService } from '../authentication/auth-service.service';


@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private http: HttpClient, private auth:AuthServiceService) { }

  private servicesUrl:string = 'http://www.giftokarachi.com/';



  //get 
  public get(url: string){ 
      let serviceUrl = this.servicesUrl + url;
      return this.http.get(serviceUrl); 
  }

  public getData(url: string){ 
    let getToken = this.auth.getToken();

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'token': getToken.token
      })
    }

    let serviceUrl = this.servicesUrl + url;
    return this.http.get(serviceUrl, httpOptions ); 
  }
 
  public post(url: string, payload: Object){
    let serviceUrl = this.servicesUrl + url;
    return this.http.post(serviceUrl, payload);
  }

  //token services
  public getSData(url: string, params){ 
    
    let getToken = this.auth.getToken();

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'token': getToken.token,
      })
    }

    let serviceUrl = this.servicesUrl + url;
    return this.http.get(serviceUrl + params, httpOptions ); 
  }

  
  //token services
  public postSData(url: string, payload:object){ 
    let getToken = this.auth.getToken();

    let httpOptions = {
      body: {...payload, 'token': getToken.token}
    }

    let serviceUrl = this.servicesUrl + url;
    return this.http.post(serviceUrl, {...payload, 'token': getToken.token}); 
  }

  public sentToken(){
    return this.auth.getToken();
  }

  // public put(url: string, payload: Object): Observable<BaseResponseModule<any>> {
  //   return this.http.put<BaseResponseModule<any>>(url, JSON.stringify(payload));
  // }

  public getSDataHeader(url: string, data){ 
    
    let getToken = this.auth.getToken();

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'token': getToken.token,
        ...data
      })
    }

    let serviceUrl = this.servicesUrl + url;
    return this.http.get(serviceUrl, httpOptions ); 
  }


}
