<div class="container">
    <div class="row" *ngIf="success">
        <div class="col-12">
            <div class="p-3 mb-4 bg-success text-white"> {{success}}</div>
           
        </div>
    </div>
    <form #price="ngForm" (ngSubmit)="addCategory(price)">
    <div class="row">
        <div class="col-lg-12">

                <h2>Category Price</h2>
                <div class="form-row">
                    <div class="col-md-3 mb-3">
                        <label for="validationTooltip01">Client</label> 
                        
                        <select class="custom-select" [(ngModel)]="getFormData.client_id" name="client_id" #client_id="ngModel" (change)="categoryGet(getFormData.client_id)"  required>
                            <option *ngFor="let item of client" [ngValue]="item.client_id" >{{item.company_name}}</option>
                        </select>
                        <div [hidden]="client_id.valid || client_id.pristine" class="alert alert-danger">
                            Client is required
                        </div>
                    </div>

                    <div class="col-md-3 mb-3" *ngIf="getFormData.client_id">
                        <label for="validationTooltip01">Category</label> 
                        
                        <select class="custom-select" [(ngModel)]="getFormData.delivery" name="delivery" #delivery="ngModel" (change)="checkCategory(getFormData.delivery)"  required>
                            <option *ngFor="let item of category" [ngValue]="item" >{{item.del_place}}</option>
                        </select>
                        <div [hidden]="delivery.valid || delivery.pristine" class="alert alert-danger">
                            Category is required
                        </div>
                    </div>
                   
                </div>
                <div class="form-row" *ngIf="checkdelivery"> 
                    <div class="col-md-2 mb-3">
                        <label for="validationTooltip02">liter 19</label>
                        <input type="number" class="form-control" [(ngModel)]="getFormData.ltr19" name="ltr19"  #ltr19="ngModel" required>
                        <div [hidden]="ltr19.valid || ltr19.pristine" class="alert alert-danger">
                            liter 19 is required
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="validationTooltip02">liter 12</label>
                        <input type="number" class="form-control" [(ngModel)]="getFormData.ltr12" name="ltr12"  #ltr12="ngModel" required>
                        <div [hidden]="ltr12.valid || ltr12.pristine" class="alert alert-danger">
                            liter 12 is required
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="validationTooltip02">liter 6</label>
                        <input type="number" class="form-control" [(ngModel)]="getFormData.ltr6" name="ltr6"  #ltr6="ngModel" required>
                        <div [hidden]="ltr6.valid || ltr6.pristine" class="alert alert-danger">
                            liter 6 is required
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="validationTooltip02">liter 1.5</label>
                        <input type="number" class="form-control" [(ngModel)]="getFormData.ltr1_5" name="ltr1_5"  #ltr1_5="ngModel" required>
                        <div [hidden]="ltr1_5.valid || ltr1_5.pristine" class="alert alert-danger">
                            liter 1.5 is required
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="validationTooltip02">ml 600</label>
                        <input type="number" class="form-control" [(ngModel)]="getFormData.ml600" name="ml600"  #ml600="ngModel" required>
                        <div [hidden]="ml600.valid || ml600.pristine" class="alert alert-danger">
                            ml 600 is required
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="validationTooltip02">ml 300</label>
                        <input type="number" class="form-control" [(ngModel)]="getFormData.ml330" name="ml330"  #ml330="ngModel" required>
                        <div [hidden]="ml330.valid || ml330.pristine" class="alert alert-danger">
                            ml 300 is required
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="validationTooltip02">liter 1000</label>
                        <input type="number" class="form-control" [(ngModel)]="getFormData.ltr1000" name="ltr1000"  #ltr1000="ngModel" required>
                        <div [hidden]="ltr1000.valid || ltr1000.pristine" class="alert alert-danger">
                            liter 1000 is required
                        </div>
                    </div>
                    
                </div>

                <div class="form-row" *ngIf="filler"> 
                    <div class="col-md-2 mb-3">
                        <label for="validationTooltip02">Filler</label>
                        <input type="number" class="form-control" [(ngModel)]="getFormData.filler" name="filler"  #filler="ngModel" required>
                        <div [hidden]="filler.valid || filler.pristine" class="alert alert-danger">
                            Filler is required
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="validationTooltip02">liter 19</label>
                        <input type="number" class="form-control" [(ngModel)]="getFormData.ltr19" name="ltr19"  #ltr19="ngModel" required>
                        <div [hidden]="ltr19.valid || ltr19.pristine" class="alert alert-danger">
                            liter 19 is required
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="validationTooltip02">liter 12</label>
                        <input type="number" class="form-control" [(ngModel)]="getFormData.ltr12" name="ltr12"  #ltr12="ngModel" required>
                        <div [hidden]="ltr12.valid || ltr12.pristine" class="alert alert-danger">
                            liter 12 is required
                        </div>
                    </div>
                </div>

            

               
                <button class="btn btn-primary" type="submit" [disabled]="!price.form.valid" *ngIf="!loadingButton">Submit</button>
                <button class="btn btn-primary" *ngIf="loadingButton">
                    <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </button>
            

        </div>
    
    </div>
</form>
</div>