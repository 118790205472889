import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ServicesService } from 'src/app/services/services.service';
import { DateRange } from '@uiowa/date-range-picker';
import { jsPDF } from "jspdf";
import { DatePipe } from '@angular/common';
import autoTable from 'jspdf-autotable';
// import * as jsPDF from 'jspdf';
// import 'jspdf-autotable';
// import * as jsPDF from 'jspdf' 

@Component({
  selector: 'app-sale-report',
  templateUrl: './sale-report.component.html',
  styleUrls: ['./sale-report.component.scss'],
  providers: [DatePipe]
})
export class SaleReportComponent implements OnInit {

  private serviceName = 'sale';
  loadingPage = false;
  loadingButton = false;

  dateRange = new DateRange();
  public formDataOne:any = [];

  public report:any = [];

  public cehckValue = true;

  public Total = 0;
  public Received = 0;
  public Balance = 0;
  public Discount = 0;

  @ViewChild('htmlData') htmlData:ElementRef;

  constructor(private services: ServicesService, private datePipe: DatePipe) { }

  ngOnInit(): void {
  }

  // checkValue(){
  //   if(this.formDataOne.cell === undefined){
  //     this.formDataOne.cell = "";
  //   }

  //   if(this.formDataOne.address === undefined){
  //     this.formDataOne.address = "";
  //   }

  //   if(this.formDataOne.cell != "" || this.formDataOne.address != ""){
  //     this.cehckValue = false;
  //   }else{
  //     this.cehckValue = true;
  //   }
  // }

  saleReport() { 
    this.loadingPage = true;

    let startDate = '', endDate = '';
    if(this.dateRange.start){
      startDate =   this.dateRange.start.toISOString().substr(0,10);
      endDate   =   this.dateRange.end.toISOString().substr(0,10);
    }
    let cell;
    if(this.formDataOne.cell != undefined){
      cell = this.formDataOne.cell.replace(/\D/g, '');
      if(cell.length != 11){
        cell = "";
        this.formDataOne.cell = "";
        //alert('your number is wrong')
      }
    }else{
      cell = "";
    }

    
    
    if(this.formDataOne.address === undefined){
      this.formDataOne.address = "";
    }
    
    

    let client_id = this.services.sentToken().client_id; 
    
    //let sendData = "?client_id=" + client_id +  "&cell=" + cell +  "&address=" + this.formDataOne.address + "&start_date=" + startDate + "&end_date=" + endDate;

    let sendData = {client_id: JSON.stringify(client_id), cell:cell, address:this.formDataOne.address, start_date:startDate, end_date:endDate}

    this.services.getSDataHeader(this.serviceName, sendData).pipe(first()).subscribe(
      (data: any) => {

        this.loadingPage = false;
        this.report = data.report;

        if(this.formDataOne.address === undefined){
          this.formDataOne.address = "";
        }
        if(this.formDataOne.cell === undefined){
          this.formDataOne.cell = "";
        }
        if(this.formDataOne.cell != "" || this.formDataOne.address != ""){
          this.cehckValue = false;
          this.Total = 0; this.Received = 0; this.Balance = 0; this.Discount = 0;
          this.report.forEach(element => {
            this.Total += element.payment;
            this.Received += element.pay_received;
            this.Discount += element.discount;
          });
          this.Balance = this.Total - this.Received;
        }else{
          this.cehckValue = true;
        }
      },
      (error) => {
        this.loadingPage = false;
        console.log(error);
      }
    );
  }

  generateData() {
    var result = [];

    var data;

    let reportData = this.report;

    for (var i = 0; i < reportData.length; i += 1) {
      //data.id = (i + 1).toString();
      data = {
        Id:JSON.stringify(reportData[i].user_id),
        Date: this.datePipe.transform(reportData[i].date, "yyyy-MM-dd"),
        Filler:JSON.stringify(reportData[i].filler),
        "Ltr1.5":JSON.stringify(reportData[i].ltr1_5),
        Ltr6:JSON.stringify(reportData[i].ltr6),
        Ltr12:JSON.stringify(reportData[i].ltr12),
        Ltr19:JSON.stringify(reportData[i].ltr19),
        Ltr1000:JSON.stringify(reportData[i].ltr1000),
        Ml330:JSON.stringify(reportData[i].ml330),
        Ml600:JSON.stringify(reportData[i].ml600),
        "Delivery":reportData[i].delivery_type,
        Discount:JSON.stringify(reportData[i].discount),
        Total:JSON.stringify(reportData[i].payment),
        Received:JSON.stringify(reportData[i].pay_received),
        Balance:JSON.stringify(reportData[i].balance)
      }; 
      result.push(Object.assign({}, data));

    }

    return result;
  };

  public downloadPDF():void {
 
     let setPdfName = this.report[0];

    let pdfSaveName = setPdfName.name+'-'+setPdfName.address;
    pdfSaveName = pdfSaveName.replace(/\s+/g, '');
    let company_name = this.services.sentToken().company_name; 
    

   
    
    // function createHeaders(keys) {
    //   var result = [];
    //   for (var i = 0; i < keys.length; i += 1) {
    //     result.push({
    //       id: keys[i],
    //       name: keys[i],
    //       prompt: keys[i],
    //       width: 80,
    //       align: "center",
    //       padding: 0
    //     });
    //   }
    //   return result;
    // }
    
    // var headers = createHeaders([
    //   "Id",
    //   "Date",
    //   "Filler",
    //   "Ltr1.5",
    //   "Ltr6",
    //   "Ltr12",
    //   "Ltr19",
    //   "Ltr1000",
    //   "Ml330",
    //   "Ml600",
    //   "Delivery",
    //   "Discount",
    //   "Total",
    //   "Received",
    //   "Balance"
    // ]);
    
    var doc = new jsPDF('p','pt', 'a4');
    

    doc.text("Name: " + setPdfName.name, 5, 40);
    doc.text("Cell: " + setPdfName.cell, 300, 40);
    doc.text("Address: " + setPdfName.address, 5, 60);

    // doc.text("Discount: " + this.Discount, 5, 50);
    // doc.text("Total: " + this.Total, 80, 40);
    // doc.text("Received: " + this.Received, 150, 40);
    // doc.text("Balance: " + this.Balance, 250, 40);
    doc.setFont("helvetica", "bold");
    doc.text(company_name, 5, 20); 
    //doc.table(5, 50, this.generateData(), headers, { autoSize: true });
    autoTable(doc, { html: '#my-table', margin: { top: 70, left:5, right:5 }, });
    doc.save(pdfSaveName+'.pdf');
    
  }

}
