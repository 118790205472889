import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthServiceService } from '../auth-service.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  constructor(private auth: AuthServiceService, private router: Router,) { }

  private serviceName:string = "userlogin";

  ngOnInit(): void {
    if(this.auth.getToken() != null){
      this.router.navigate(['/dashboard'], { skipLocationChange : true});
    }
  }

  signIn(form: NgForm){
     this.auth.signinUser(this.serviceName, form.value);
     //console.log(data);
  }

}
