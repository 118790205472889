import { Component } from '@angular/core';
import { AuthServiceService } from './authentication/auth-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'giftokarachi';

  constructor( public auth: AuthServiceService){}

}
