import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ServicesService } from 'src/app/services/services.service';
import Utilite from 'src/app/utilities/utilite';

@Component({
  selector: 'app-add-category-price',
  templateUrl: './add-category-price.component.html',
  styleUrls: ['./add-category-price.component.scss']
})
export class AddCategoryPriceComponent implements OnInit {

  public getFormData:any = [];
  public items;
  private serviceName = 'client';

  public loadingButton = false;
  public checkdelivery = false;
  public filler = false;

  public success: string;

  public client:any = [];
  public category:any = [];

 

  constructor(  private services: ServicesService) { 
  
  }

  ngOnInit(): void {
    this.clientGet();
  }

  addCategory(product : NgForm){
    this.loadingButton = true;
    let counterHome;

    if(this.checkdelivery){
      counterHome = [
        {"client_id": this.getFormData.client_id, "delivery_id":this.getFormData.delivery.delivery_id, "name":"ltr19", "payment":this.getFormData.ltr19},
        {"client_id": this.getFormData.client_id, "delivery_id":this.getFormData.delivery.delivery_id, "name":"ltr12", "payment":this.getFormData.ltr12},
        {"client_id": this.getFormData.client_id, "delivery_id":this.getFormData.delivery.delivery_id, "name":"ltr6", "payment":this.getFormData.ltr6}, 
        {"client_id": this.getFormData.client_id, "delivery_id":this.getFormData.delivery.delivery_id, "name":"ltr1_5", "payment":this.getFormData.ltr1_5},
        {"client_id": this.getFormData.client_id, "delivery_id":this.getFormData.delivery.delivery_id, "name":"ml600", "payment":this.getFormData.ml600},
        {"client_id": this.getFormData.client_id, "delivery_id":this.getFormData.delivery.delivery_id, "name":"ml330", "payment":this.getFormData.ml330},
        {"client_id": this.getFormData.client_id, "delivery_id":this.getFormData.delivery.delivery_id, "name":"ltr1000", "payment":this.getFormData.ltr1000},
      ];
    }

    if(this.filler){
      counterHome = [
        {"client_id": this.getFormData.client_id, "delivery_id":this.getFormData.delivery.delivery_id, "name":"filler", "payment":this.getFormData.filler},
        {"client_id": this.getFormData.client_id, "delivery_id":this.getFormData.delivery.delivery_id, "name":"ltr19", "payment":this.getFormData.ltr19},
        {"client_id": this.getFormData.client_id, "delivery_id":this.getFormData.delivery.delivery_id, "name":"ltr12", "payment":this.getFormData.ltr12}
      ];
    }
   

    //console.log(counterHome);
    

    this.services.postSData(this.serviceName+"/category", {category:counterHome}).pipe(first()).subscribe(
      (data: any) => {
        this.loadingButton = false;
        this.success = data.Message;
        //this.getFormData = [];
      },
      (error) => {
        this.loadingButton = false;
        console.log(error);
      }
    );
    
  }

  clientGet(){
    this.loadingButton = true;
    this.services.getData(this.serviceName).pipe(first()).subscribe(
      (data: any) => {
        this.loadingButton = false;
        //this.success = data.Message;
        this.client = data.data;
        //console.log(this.client);
      },
      (error) => {
        this.loadingButton = false;
        console.log(error);
      }
    );
  }

  categoryGet(client_id){
    let sendData = "?client_id=" + client_id;
    this.services.getSData(this.serviceName, sendData).pipe(first()).subscribe(
      (data: any) => {
        this.category = data.data;
        //console.log(this.category);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  checkCategory(item){
    // console.log(item);
    if(item.del_place === "Counter Sale" || item.del_place === "Home"){
      this.checkdelivery = true;
      this.filler = false;
    }else{
      this.checkdelivery = false;
      this.filler = true;
    }
  }

}
