import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DateRangePickerModule } from '@uiowa/date-range-picker';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './component/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { SignInComponent } from './authentication/sign-in/sign-in.component';
import { NotfoundComponent } from './component/notfound/notfound.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { AddClientComponent } from './dashboard/add-client/add-client.component';
import { AddCategoryPriceComponent } from './dashboard/add-category-price/add-category-price.component';
import { SaleReportComponent } from './component/client/sale-report/sale-report.component';
import { PhoneMaskDirective } from './directive/phone-mask.directive';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SignInComponent,
    NotfoundComponent,
    DashboardComponent,
    AddClientComponent,
    AddCategoryPriceComponent,
    SaleReportComponent,
    PhoneMaskDirective
  ],
  exports:[
    PhoneMaskDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    DateRangePickerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
