import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ServicesService } from 'src/app/services/services.service';
import Utilite from 'src/app/utilities/utilite';


@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {


  public getFormData:any = [];
  public items;
  private serviceName = 'client';

  public loadingButton = false;

  public success: string;

  constructor(  private services: ServicesService) { 
  
  }

  ngOnInit(): void {
  }

  addClient(product : NgForm){
    this.loadingButton = true;
    let todayDate = Utilite.dateGet('-');
    let addClientUser = {
      "company_name": this.getFormData.company_name,
      "cell": this.getFormData.cell,
      "address": this.getFormData.address,
      "create_date": todayDate,
      "check": this.getFormData.check,
      "email": this.getFormData.email,
      "password": this.getFormData.password,
      "name": this.getFormData.name,
      "dob": this.getFormData.dob,
    }

    

    console.log(addClientUser);
    this.services.postSData(this.serviceName, addClientUser).pipe(first()).subscribe(
      (data: any) => {
        this.loadingButton = false;
        this.success = data.Message;
        this.getFormData = [];
      },
      (error) => {
        this.loadingButton = false;
        console.log(error);
      }
    );
    
  }

  

}
